<template>
	<div id="app">
		<component :is="topLayout" :settings="topLayoutSettings" />
		<main id="main-wrapper" class="track-main-content">
			<transition :name="transitionName">
				<router-view :topLayout.sync="topLayout" :topLayoutSettings.sync="topLayoutSettings"/>
			</transition>
		</main>
		<nav id="nav" class="track-bottom-navigation">
			<router-link :to="{ name: 'Home' }">
				<img alt="Home Icon" src="@/assets/nav/home-icon.png">
				<img alt="Home Icon" class="selected" src="@/assets/nav/home-icon-selected.png">
				<span class="link-text">Home</span>
			</router-link>
			<router-link :to="{ name: 'Community Stories' }">
				<img alt="Community Stories Icon" src="@/assets/nav/blog-icon.png">
				<img alt="Community Stories Icon" class="selected" src="@/assets/nav/blog-icon-selected.png">
				<span class="link-text">Community</span>
			</router-link>
			<router-link :to="{ name: 'My Trips' }">
				<img alt="My Trips Icon" src="@/assets/nav/trips-icon.png">
				<img alt="My Trips Icon" class="selected" src="@/assets/nav/trips-icon-selected.png">
				<span class="link-text">My Trips</span>
			</router-link>
			<router-link :to="{ name: 'Profile' }">
				<img alt="Profile Icon" src="@/assets/nav/profile-icon.png">
				<img alt="Profile Icon" class="selected" src="@/assets/nav/profile-icon-selected.png">
				<span class="link-text">Profile</span>
			</router-link>
		</nav>
		<footer>
			<div class="inner-wrapper">
				Protected by <router-link :to="{ name: 'Licences'}">TruTravels Limited - ATOL 11807, ABTA Y6506</router-link> and <router-link :to="{ name: 'Privacy Policy'}">Privacy Policy</router-link>
			</div>
		</footer>
		<div class="popup outer cookies" v-if="showCookiePopup && $route.name != 'Privacy Policy'">
			<div class="inner">
				<h4 class="title">Your Privacy</h4>
				<img src="@/assets/privacy-icon.png" class="privacy-icon" alt="Privacy Icon">
				<div class="content">
					<p>We use cookies and similar technologies to help personalise content, tailor and measure ads, and provide a better experience. By clicking Accept, you agree to this as outlined in our <router-link :to="{ name: 'Privacy Policy' }">Privacy Policy</router-link>.</p>
					<!-- <p>To change preferences or withdraw consent, please update your Cookie Preferences. </p> -->
				</div>
				<div class="button" @click="acceptCookies()">Accept</div>

				<!-- TODO: Make this a "View policy" link or "manage cookies" link. waiting on content from mark/joe -->
				<!-- <div class="button disablDed" @click="manageCookies()">Manage Cookies</div> -->
				
			</div>
			<div class="bg"></div>
		</div>
	</div>
</template>
<script>
	import { mapState, mapActions } from 'vuex';
	import LayoutDefault from '@/views/layouts/LayoutDefault.vue';

	export default {
		name: 'Purpose',
		metaInfo() {
			return {
				title: process.env.VUE_APP_TITLE,
				meta: [
					{
						// vmid: "description",
						name: "google-signin-client_id",
						content: "352368229046-2t2lcijnavvjusufsoelgscothphepmp.apps.googleusercontent.com"
						// content: "454293056308-6ovbs8n9uri5ne1ciplods9mbr3cpatp.apps.googleusercontent.com"
					}
				]
			};
		},
		data() {
			return {
				topLayout: LayoutDefault, // Default
				// allTopLayoutSettings: {
				// 	left: { // Defines the left button/icon
				// 		type: '', // back or home
				// 		color: '', // white or defaults to black
				// 		route: '' // Any valid router route, e.g. { name: 'Home' } or '/home' etc
				// 	},
				// 	right: { // Defines the right button/icon
				// 		type: '', // menu or exit
				// 		route: '' // Any valid router route, e.g. { name: 'Home' } or '/home' etc
				// 	},
				// 	overlayContent: false, // Defines if the layout should overlay the content below or not (for header images etc)
				// 	hide: false, // Hides the layout completely. Useful if the template should handle the layout itself.
				// },
				topLayoutSettings: { // Default
					hide: true, // Hides the layout completely. Useful if the template should handle the layout itself.
				},

				showCookiePopup: true,

				transitionName: "nothing",
			}
		},
		watch: {    
			$route(to, from) { 
				// console.log(to);

				if(to.name != from.name) { // Only if we are actually changing routes
					// Reset the layout to hidden, just for any views that haven't updated to the new system yet.
					this.topLayoutSettings = {
						hide: true, // Hides the layout completely. Useful if the template should handle the layout itself.
					};
				}
				
				// Transitions 
				if(to.meta.transitionName && from.meta.transitionName) {
					// If both pages are slide transitions
					if(to.meta.transitionName == "slide" && from.meta.transitionName == "slide") { 
						// Determine which way to slide
						let toOrder = to.meta.order;
						let fromOrder = from.meta.order ? from.meta.order : 0;
						this.transitionName = toOrder >= fromOrder ? 'slide-right' : 'slide-left'    
					} else if (to.meta.transitionName == from.meta.transitionName) { 
						// If both pages have the same transition set
						this.transitionName = to.meta.transitionName; // Set based on route settings
					}
				}
				 else { 
					// If they have different transitions, use fade as default
					this.transitionName = "fade";
				}
			},  
		},
		computed: {
			...mapState({
				alert: state => state.alert,
				account: state => state.account,
				basket: state => state.basket,
			}),
		},
		methods: {
			...mapActions({
				clearAlert: 'alert/clear',
				initBasket: 'basket/initBasket',
				initAccount: 'account/initAccount',
			}),
			acceptCookies() {
				this.$cookies.set("gdpr", "true", "7d")
				this.showCookiePopup = false;
			},
			manageCookies() {
				// TODO: Where does manage cookies take us? What functionality does this entail?
				// console.log("Manage Cookies");
			},
		},
		created() {
			this.clearAlert();
			this.initBasket();
			this.initAccount();

			var gdprCookie = this.$cookies.get("gdpr");
			if(gdprCookie == "true") {
				this.showCookiePopup = false;
			}
		},
	};
</script>

<style>
	/* Keep global styles inside this main App.vue. Any component/view specific styles should be kept in that specific component/view. */
	/* @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap'); */
	@font-face {
		font-family: 'Inter';
		font-style:  normal;
		font-weight: 100;
		font-display: swap;
		src: url("fonts/Inter-Thin.woff2?v=3.19") format("woff2"),
			url("fonts/Inter-Thin.woff?v=3.19") format("woff");
	}
	@font-face {
		font-family: 'Inter';
		font-style:  italic;
		font-weight: 100;
		font-display: swap;
		src: url("fonts/Inter-ThinItalic.woff2?v=3.19") format("woff2"),
			url("fonts/Inter-ThinItalic.woff?v=3.19") format("woff");
	}

	@font-face {
		font-family: 'Inter';
		font-style:  normal;
		font-weight: 200;
		font-display: swap;
		src: url("fonts/Inter-ExtraLight.woff2?v=3.19") format("woff2"),
			url("fonts/Inter-ExtraLight.woff?v=3.19") format("woff");
	}
	@font-face {
		font-family: 'Inter';
		font-style:  italic;
		font-weight: 200;
		font-display: swap;
		src: url("fonts/Inter-ExtraLightItalic.woff2?v=3.19") format("woff2"),
			url("fonts/Inter-ExtraLightItalic.woff?v=3.19") format("woff");
	}

	@font-face {
		font-family: 'Inter';
		font-style:  normal;
		font-weight: 300;
		font-display: swap;
		src: url("fonts/Inter-Light.woff2?v=3.19") format("woff2"),
			url("fonts/Inter-Light.woff?v=3.19") format("woff");
	}
	@font-face {
		font-family: 'Inter';
		font-style:  italic;
		font-weight: 300;
		font-display: swap;
		src: url("fonts/Inter-LightItalic.woff2?v=3.19") format("woff2"),
			url("fonts/Inter-LightItalic.woff?v=3.19") format("woff");
	}

	@font-face {
		font-family: 'Inter';
		font-style:  normal;
		font-weight: 400;
		font-display: swap;
		src: url("fonts/Inter-Regular.woff2?v=3.19") format("woff2"),
			url("fonts/Inter-Regular.woff?v=3.19") format("woff");
	}
	@font-face {
		font-family: 'Inter';
		font-style:  italic;
		font-weight: 400;
		font-display: swap;
		src: url("fonts/Inter-Italic.woff2?v=3.19") format("woff2"),
			url("fonts/Inter-Italic.woff?v=3.19") format("woff");
	}

	@font-face {
		font-family: 'Inter';
		font-style:  normal;
		font-weight: 500;
		font-display: swap;
		src: url("fonts/Inter-Medium.woff2?v=3.19") format("woff2"),
			url("fonts/Inter-Medium.woff?v=3.19") format("woff");
	}
	@font-face {
		font-family: 'Inter';
		font-style:  italic;
		font-weight: 500;
		font-display: swap;
		src: url("fonts/Inter-MediumItalic.woff2?v=3.19") format("woff2"),
			url("fonts/Inter-MediumItalic.woff?v=3.19") format("woff");
	}

	@font-face {
		font-family: 'Inter';
		font-style:  normal;
		font-weight: 600;
		font-display: swap;
		src: url("fonts/Inter-SemiBold.woff2?v=3.19") format("woff2"),
			url("fonts/Inter-SemiBold.woff?v=3.19") format("woff");
	}
	@font-face {
		font-family: 'Inter';
		font-style:  italic;
		font-weight: 600;
		font-display: swap;
		src: url("fonts/Inter-SemiBoldItalic.woff2?v=3.19") format("woff2"),
			url("fonts/Inter-SemiBoldItalic.woff?v=3.19") format("woff");
	}

	@font-face {
		font-family: 'Inter';
		font-style:  normal;
		font-weight: 700;
		font-display: swap;
		src: url("fonts/Inter-Bold.woff2?v=3.19") format("woff2"),
			url("fonts/Inter-Bold.woff?v=3.19") format("woff");
	}
	@font-face {
		font-family: 'Inter';
		font-style:  italic;
		font-weight: 700;
		font-display: swap;
		src: url("fonts/Inter-BoldItalic.woff2?v=3.19") format("woff2"),
			url("fonts/Inter-BoldItalic.woff?v=3.19") format("woff");
	}

	@font-face {
		font-family: 'Inter';
		font-style:  normal;
		font-weight: 800;
		font-display: swap;
		src: url("fonts/Inter-ExtraBold.woff2?v=3.19") format("woff2"),
			url("fonts/Inter-ExtraBold.woff?v=3.19") format("woff");
	}
	@font-face {
		font-family: 'Inter';
		font-style:  italic;
		font-weight: 800;
		font-display: swap;
		src: url("fonts/Inter-ExtraBoldItalic.woff2?v=3.19") format("woff2"),
			url("fonts/Inter-ExtraBoldItalic.woff?v=3.19") format("woff");
	}

	@font-face {
		font-family: 'Inter';
		font-style:  normal;
		font-weight: 900;
		font-display: swap;
		src: url("fonts/Inter-Black.woff2?v=3.19") format("woff2"),
			url("fonts/Inter-Black.woff?v=3.19") format("woff");
	}
	@font-face {
		font-family: 'Inter';
		font-style:  italic;
		font-weight: 900;
		font-display: swap;
		src: url("fonts/Inter-BlackItalic.woff2?v=3.19") format("woff2"),
			url("fonts/Inter-BlackItalic.woff?v=3.19") format("woff");
	}

	/* fonts */
	h1, .heading1 {
		margin:0;
		font-weight: bold;
		font-size: 64px;
		line-height: 77px;
	}
	h2, .heading2 {
		margin:0;
		font-weight: bold;
		font-size: 48px;
		line-height: 58px;
	}
	h3, .heading3 {
		margin:0;
		font-weight: bold;
		font-size: 34px;
		line-height: 41px;
	}
	h4, .heading4 {
		margin:0;
		font-weight: bold;
		font-size: 28px;
		line-height: 34px;
	}
	h5, .heading5 {
		margin:0;
		font-weight: bold;
		font-size: 24px;
		line-height: 24px;
	}
	h6, .heading6 {
		margin:0;
		font-weight: bold;
		font-size: 20px;
		line-height: 24px;
	}
	.heading7 {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: black;
		margin-bottom: 5px;
		margin-bottom: 20px;
	}
	p {
		font-size: 16px;
		line-height: 26px;
	}
	.body-large {
		font-size: 18px;
		line-height: 28px;
	}
	.caption {
		font-size: 14px;
		line-height: 24px;
	}
	.footnote {
		font-size: 12px;
		line-height: 15px;
	}
	.heading {
		font-size: 20px;
		font-weight: 700;
		margin-bottom: 10px;
	}
	/* end fonts */

	html {
		height: 100%; 
	}
	body {
		margin: 0;
	}
	#app {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
		font-family: 'Inter', sans-serif;

		/* flexbox stuff so that the footer can be always positioned at the bottom*/
		display: flex;
		min-height: 100vh;
    	flex-direction: column;
	}
	#main-wrapper {
		/* overflow: hidden; */
		position: relative;
		/* min-height: calc(100vh - 240px); */
		/* flexbox stuff so that the footer can be always positioned at the bottom*/
		flex: 1 0 auto;  /* Will expand to take up available space, pushing the footer down */
		min-width: 320px; /* So that the min-width on mobile is set. Will scroll horizontally on smaller screens. */
	}
	footer {
		font-size: 12px;
		text-align: center;
		color: #2c3e50;
		padding-top: 20px;
		padding-bottom: 95px; /* To compensate for the bottom navigation */
		background-color: #f5f5f5;
		margin-top: 10px;

		/* flexbox stuff so that the footer can be always positioned at the bottom*/
		flex-shrink: 0;  /* Prevents footer from shrinking */
		min-width: 320px; /* So that the min-width on mobile is set. Will scroll horizontally on smaller screens. */
	}
	.full-height {
		 /* Double the footer height so that it is displayed at the bottom of the screen. */
		min-height: calc(100vh - 155px);
	}
	.outer-wrapper {
		width: 100%; 
		margin: auto;
		overflow: hidden;
	}
	/* Limit the width of the content for these pages, until there is a better design for larger screens */
	.outer-wrapper.mobile-only {
		max-width: 380px;
	}

	.inner-wrapper {
		padding: 0 12px;
	}
	/* .swiper-button-prev, .swiper-button-next {
		display: none !important;
	} */
	.clear {
		clear: both;
	}

	@media (min-width: 576px) { /* Tablets & Large Mobiles */
		.outer-wrapper {
			max-width: 576px;
		}
		/* .swiper-button-prev, .swiper-button-next {
			display: block;
		}	 */
	}
	@media (min-width: 960px) { /* Tablets */
		.outer-wrapper {
			max-width: 880px;
		}
		/* .swiper-button-prev, .swiper-button-next {
			display: block;
		}	 */
	}
	@media (min-width: 1200px) { /* Desktop */
		.outer-wrapper {
			max-width: 1120px;
		}
	}

	/* Navigation */
	#top-nav {
		position: relative;
		height: 85px;
	}

	#nav {
		position: fixed;
		bottom: 0;
		right: 0;
		left: 0;
		width: 100%;
		height: 75px;
		padding-top: 12px;
		box-sizing: border-box;
		background-color: #FBFBFB;
		white-space: nowrap;
		text-align: center;
		z-index: 20;
	}
	/* Media query for screens smaller than 400px */
	@media screen and (max-height: 300px) {
		#nav {
			position: unset;
		}
	}

	#nav a {
		display: inline-block;
		text-decoration: none;
		width: 95px;
		max-width: 25%;
		position: relative;
	}
	#nav .link-text {
		display: block;
		color: #748087;
		font-size: 12px;
		margin-top: 8px;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}
	#nav .router-link-exact-active .link-text {
		color: #118AB2;
	}
	#nav img {
		display: inline;
		text-align: center;
		width: 24px;
	}
	#nav img.selected {
		display: none;
	}
	#nav .router-link-exact-active img {
		display: none;
	}
	#nav .router-link-exact-active img.selected {
		display: inline;
	}
	/* End Navigation */

	/* Main heading*/
	.heading-section {
		padding-top: 15px;
		text-align: center;
		color: #000000;
	}

	
	.button {
		background: #06D6A0;
		cursor: pointer;
		width: 257px;
		height: 50px;
		border-radius: 50px;
		font-weight: bold;
		font-size: 16px;
		line-height: 26px;
		color: #FCFCFE;
		border: none;
		padding: 3px 5px;
  		box-sizing: border-box;
		font-family: 'Inter', sans-serif;
	}
	.button.small {
		width: 120px;
		line-height: 17px;
	}
	.button.tiny {
		width: 69px;
		height: auto;
		min-height: 23px;
		margin-right: 10px;
		font-size: 14px;
		line-height: 14px;
	}
	.button.disabled {
		background-color: #E0E0E0;
	}
	.button.blue {
		background-color: #118AB2;
	}
	.button.red {
		background-color: #FA5252;
	}
	.alert {
		font-size: 14px;
		text-align: center;
		padding-bottom: 24px;
		font-weight: bold;
		padding-left: 5px;
		padding-right: 5px;
		padding-top: 5px;
	}
	.back-icon {
		height: 29px;
		position: absolute;
		top: 25px;
		left: 23px;
		z-index: 10;
	}
	.close-icon {
		width: 24px;
		position: absolute;
		top: 25px;
		right: 23px;
	}
	.alert-danger {
		color: #FF0000;
	}

	.alert-success {
		color: #00FF00;
	}
	.invalid-feedback {
		font-size: 12px;
		color: #FF0000;
		text-align: center;
		padding: 10px;
		font-weight: 400;
	}
	.invalid-feedback.number {
		margin-top: 15px;
	}

	/* Select a search type area */
	/** Type 1 **/
	.add-more {
		font-size: 20px;
		font-weight: 700;
		text-align: left;
		color: #1D1D1D;
	}
	.add-more .text {
		text-align: left;
	}
	.add-more .add-more-container {
		display: inline-flex;
		margin: 20px auto;
	}
	.add-more .add-more-container .item-wrapper {
		display: flex;
		align-items: center;
		text-decoration: none;
		margin: 0px 20px 0 0;
		cursor: pointer;
	}
	.add-more .add-more-container .item-wrapper .item {
		width: 64px;
		height: 64px;
		border: 1px solid #E5E5E5;
		border-radius: 32px;
		text-align: center;
		display: flex;
		align-items: center;
		background-color: white;
	}
	.add-more .add-more-container .item-wrapper .item img {
		display: block;
		margin: 0 auto;
	}
	.add-more .add-more-container .item-wrapper .item-text {
		display: inline-block;
		color: black;
		font-weight: 600;
		font-size: 12px;
		padding: 0 10px;
	}
	.add-more .add-more-container .item-wrapper.has-text {
		border: 1px solid #E5E5E5;
		border-radius: 32px;
		height: 64px;
		background-color: #FBFBFB;
	}
	.add-more .add-more-container .item-wrapper.has-text .item {
		border-top-width: 0;
		border-left-width: 0;
		border-bottom-width: 0;
	}
	.add-more .add-more-container .item-wrapper:last-child {
		margin-right: 0;
	}
	.add-more .add-more-container a {
		line-height: 84px;
	}
	/** Type 2 **/
	

	/* End of Select a search type area */


	/* Popup (Global) */ 
	.popup  {
		position: fixed;
		top: 0; right: 0; left: 0; bottom: 0;
		z-index: 10;
	}
	.popup .bg {
		background-color: rgba(0, 0, 0, 0.5);
		top: 0; right: 0; left: 0; bottom: 0;
		position: absolute;
		z-index:0;
	}
	.popup .inner {
		width: 90%;
		max-width: 370px;
		height: 80vh;
		height: calc(100% - 125px);
		margin: 0 auto;
		background-color: white;
		position: absolute;
		top: 40px; right: 15px; left: 15px; bottom: 100px;
		z-index: 1;
		padding: 20px 15px;
		text-align: center;
		overflow: auto;
		border-radius: 20px;
		box-sizing: border-box;
	}
	.popup .button {
		transition: all 0s ease;
		display: block;
		margin: 20px auto;
		line-height: 45px;
	}

	/* Popup (Cookies specific) */
	.popup .title {
		margin: 13px 0px 0px 0px;
	}
	.popup .privacy-icon {
		width: 22px;
	}
	.popup.cookies .inner {
		height: auto;
		max-height: calc(100% - 125px);
		bottom: unset;
	}

	/* Flights search and related components */
	.search {
		text-align: center;
		padding-top: 80px;
		padding: 85px 15px 0 15px;
		position: relative;
		min-height: 800px;
		width: 375px;
		max-width: 100%;
		margin: 0 auto;
		box-sizing: border-box;
	}
	
	.fixed-top {
		position: fixed;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		padding-top: 25px;
		z-index: 10;
		background-color: white;
		margin: 0 auto;
	}
	.fixed-top.outer-wrapper {
		min-height: unset;
	}
	.trip-type-holder {
		margin-bottom: 40px;
		width: 300px;
		margin: 0 auto;
		position: relative;
		margin-bottom: 20px;
	}
	.trip-type-holder .selected {
		font-size: 20px;
		color: #118AB2;
		font-weight: bold;
	}
	input#oneway, input#return {
		display: inline-block;
		height: 24px;
		margin: 0px;
		position: relative;
		z-index: 1;
		opacity: 0;
	}
	input#oneway {
		margin-right: 5px;
	}
	input#return {
		margin-left: 5px;
	}
	input#oneway, .oneway-label, input#return, .return-label {
		width: 120px;
	}
	.oneway-label, .return-label {
		display: inline-block;
		position: absolute;
		color: #CBD5E0;
		font-weight: bold;
		font-size: 20px;
	}
	.oneway-label {
		left: 30px;
	}
	.return-label {
		right: 00px;
	}
	input#oneway:hover, input#return:hover, .oneway-label:hover, .return-label:hover {
		cursor: pointer;
	}
	input#oneway:hover ~ .oneway-label, input#return:hover ~ .return-label {
		text-decoration: underline;
	}
	input#oneway:checked ~ .oneway-label, input#return:checked ~ .return-label {
		transition: all 0.5s ease;
		color: #118AB2;
		text-decoration: underline;
	}

	.datepicker {
		width: 350px;
		height: 55px;
		max-width: 100%;
		display: block;
		margin: 0 auto;
		margin-bottom: 16px;
	}
	.datepicker .input,
	.text-input {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		border-radius: 10px;
		border: 1px solid rgba(60,60,60,.26);
		padding-left: 51px;

		color: #50555C;
		background-image: url('assets/calendar-icon.png');
		background-position: left 16px center;
		background-repeat: no-repeat;
		background-size: 16px auto;
		font-size: 16px;
	}
	.vc-container {
		--customblue-100: #118AB2;
		--customblue-200: #E5E5E5;
		--customblue-300: #118AB2;
		--customblue-400: #118AB2;
		--customblue-500: #118AB2;
		--customblue-600: #118AB2;
		--customblue-700: #118AB2;
		--customblue-800: #118AB2;
		--customblue-900: #1a202c;
		--font-bold: 500 !important;
	}
	.vc-container.vc-customblue {
		--accent-100: var(--customblue);
		--accent-200: var(--customblue);
		--accent-300: var(--customblue);
		--accent-400: var(--customblue);
		--accent-500: var(--customblue);
		--accent-600: var(--customblue);
		--accent-700: var(--customblue);
		--accent-800: var(--customblue);
		--accent-900: var(--customblue);
	}
	.vc-container .vc-day-content[data-v-005dafc8]:hover {
		background-color: initial;
	}
	

	/* auth styles */
	.auth .inner-wrapper {
		/* max-width: 360px; */
		padding: 0 32px;
		margin: auto;
	}
	.auth a {
		color: #118AB2;
		text-decoration: none;
	}
	.auth .heading-section {
		padding-bottom: 47px;
	}
	.auth .form-control {
		border-top: none;
		border-left: none;
		border-right: none;
		border-bottom: 1px solid #C4C4C4;
		width: 307px;
		max-width: 100%;
		box-sizing: border-box;
	}
	.auth .form-control:focus {
		outline: none;
	}
	.auth label {
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #50555C;
	}
	.auth .form-group {
		padding-bottom: 54px;
	}
	.auth .btn-container {
		text-align: center;
	}
	.auth form {
		margin: auto;
		/* width: 80%; */
		max-width: 374px;
	}
	.auth .form-control {
		width: 100%;
	}
	@media (min-width: 576px) { /* Tablets + Large Phones */
		.auth {
			max-width: 100%;
		}
		.auth .form-group {
			/* display: grid; */
			max-width: 374px;
			margin: auto;
		}
	}

	/* Slide-out menu shared styles */
	.menu-wrapper .menu-slideout {
		position: fixed;
		top: 0;
		right: -375px;
		width: 375px;
		max-width: 100%;
		height: 100%;
		z-index: 10;
		background-color: #FFFFFF;
		transition: transform 0.5s ease;
	}
	.menu-wrapper .menu-slideout.open {
		transform: translate(-375px, 0)
	}
	.menu-wrapper .back-button {
		position: absolute;
		left: 10px;
		top: 23px;
		width: 20px;
		height: 20px;
	}
	.menu-wrapper .back-button img {
		width: 100%;
	}
	.menu-wrapper .background-overlay {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		background-color: grey;
		opacity: 0;
		z-index: 2;
		transition: all 1s ease;
		pointer-events: none;
	}
	.menu-wrapper .background-overlay.open {
		transition: all 1s ease;
		opacity: 0.5;
		pointer-events: auto;
	}
	.menu-wrapper .menu-slideout .menu-panel {
		background-color: #FFFFFF;
		width: 100%;
		height: 100%;
		margin-top: 64px;
		overflow: scroll;
	}
	/* Dynamic content (e.g. content fetched from an API) styled here. (Doesn't seem to work with scoped styles) */
	.article .img {
		max-width: 100%;
		/* width: 100% !important; */
		height: auto !important; 
	}
	.blog-card .des p {
		margin: 0;
	}
	
	/* Profile/Menu user header section */

	/* Menu styles (menu and profile) */
	.menu-items {
		list-style-type: none;
		font-size: 14px;
		text-align: left;
		padding: 0;
		margin: 0;
		padding-bottom: 170px;
	}
	.menu-items a {
		color: initial;
		text-decoration: none;
	}
	.menu-items .level1 .row {
		height: 16px;
		padding: 12px 27px;
		color: initial;
		background-color: #FBFBFB;
		border-bottom: 1px solid #dadada;
		cursor: pointer;
	}
	.menu-items .level1 .icon {
		width: 14px;
		margin-right: 5px;
		vertical-align: middle;
		margin-bottom: 2px;
	}
	.menu-items .level2 {
		
	}
	.menu-items .level2 .row {
		height: 40px;
		text-align: middle;
		box-sizing: border-box;
		padding-left: 50px;
		background-color: white;
	}
	.menu-items .sub-menu {
		display: none;
		list-style-type: none;
		font-size: 14px;
		text-align: left;
		padding: 0;
		margin: 0;
	}
	.menu-items .sub-menu .row {
		
	}
	.menu-items .sub-menu.active {
		display: block;
	}
	
	/* Profile/traveller form styles */
	.details-form fieldset {
		border: none;
		padding: 0;
		margin: 0;
	}
	.details-form .header {
		width: 336px;
		max-width: 100%;
		margin: 0 auto;
		font-size: 28px;
		font-weight: 800;
		line-height: 36px;
	}
	.details-form .pax_entry {
		max-width: 100%;
		min-height: 700px;
		width: 336px;
		margin: 0 auto;
	}
	.details-form .pax_entry span.note {
		font-size: 12px;
		line-height: 20px;
		color: #50555C;
		display: inline-block;
		margin: 10px 0px 0px 0px;
	}
	.details-form .sub-header {
		display: block;
		font-size: 20px;
		font-weight: 700;
		margin: 27px 0px 0px 0px;
		color: #118AB2;
		line-height: inherit;
	}
	.details-form .input, .details-form .dropdown.v-select {
		width: 100%;
		height: 58px;
		font-size: 16px;
		padding: 0px 0px 0px 14px;
		border: 1px solid rgba(60, 60, 60, 0.26);
		box-sizing: border-box;
	}
	.details-form .label {
		color: #898A8D;
		font-size: 12px;
		margin-top: 20px;
		margin-bottom: 4px;
		display: block;
	}
	.details-form .input.vue-tel-input {
		padding-left: 0;
	}
	.details-form .input.vue-tel-input .vti__dropdown {
		border-radius: 10px 0 0 10px;
		padding-left: 10px;
	}
	.details-form .input .vti__input,
	.details-form .input {
		font-weight: 400;
		font-size: 16px;
		line-height: 26px;
		color: #000000;
		border-radius: 10px;
	}
	.details-form .radio-select {
		max-width: 350px;
		width: 100%;
		height: 80px;
		background: #FFFFFF;
		border: 1px solid #E5E5E5;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		border-radius: 10px;
		margin-bottom: 10px;
		padding-top: 8px;
		padding-left: 10px;
		cursor: pointer;
	}
	.details-form .radio-select .name {
		display: inline-block;
		vertical-align: middle;
		font-size: 16px;
		font-weight: 400;
		line-height: 26px;
		color: black;
		max-width: 215px;
	}
	.details-form .radio-select .pic {
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
	}
	.details-form .radio-select .input[type="radio"] {
		width: 30px;
		float: right;
		display: inline-block;
		margin-top: 3px;
		margin-right: 15px;
	}
	.details-form .datepicker input {
		width: 99.76%;
		height: 60px;
		padding-left: 51px;
		border: 1px solid rgba(60, 60, 60, 0.26);
	}
	.details-form .dropdown.v-select {
		border: none;
		width: 336px;
		height: 60px;
		padding: 0px;
	}
	.details-form .gender .gender-option {
		display: inline-block;
		position: relative;
		width: 140px;
	}
	.details-form .gender .input[type="radio"] {
		height: 20px;
		/* margin: 17px 0px 0px 0px; */
		width: 44px;
		z-index: 0;
		opacity: 0;
		vertical-align: middle;
	}
	.details-form .gender .input[type="radio"]:hover {
		cursor: pointer;
	}
	.details-form .gender .checkmark {
		position: absolute;
		top: 3px;
		left: 15px;
		width: 13px;
		height: 13px;
		border-radius: 50%;
		background-color: transparent;
		border: 1px solid #000;
		z-index: -1;
	}
	.details-form .gender .input[type="radio"]:checked ~ .checkmark {
		background: #118AB2;
		border: 1px solid #118AB2;
	}
	.details-form .gender label {
		width: 60px;
		margin-left: -10px;
	}
	.details-form .gender label:first-of-type {
	}
	.details-form .button.save, .details-form .button.dont-save {
		display: block;
		margin: 0 auto;
		margin-top: 55px;
	}
	.details-form .button.save.disabled {
		background-color: #E0E0E0;
	}
	.details-form .button.dont-save {
		background-color: #118AB2;
	}
	.details-form .input:disabled {
		cursor: not-allowed;
	}
	@media only screen and (max-width: 400px) {
		.details-form .flights.pax_overview {
			padding: 0px 10px;
		}
	
		.details-form .button.save, .details-form .button.dont-save {
			margin: 27px auto 0px auto;
		}
		.details-form .button.save {
			/* margin-top: 50px; */
		}
	}

	/* Trip category select (menu and trips) */
	.trip-categories {
		display: inline-flex;
		margin: 30px auto;
		width: 100%;
		justify-content: center;
	}
	.trip-categories table td button {
		background-color: transparent;
		width: 100%;
		padding: 0;
	}
	.trip-categories table td .btn:hover .btns {
		color: #979797;
	}
	.trip-categories img {
		display: block;
		margin: 0 auto;
		width: 23px;
		height: auto;
	}
	.trip-categories img.active {
		display: none;
	}
	.trip-categories img.plus {
		width: 28px;
		margin-top: -3px;
	}
	.trip-categories .btns {
		color: #979797;
		font-size: 12px;
		line-height: 24px;
		text-align: center;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
	}
	.trip-categories .btn {
		position: relative;
		min-width: 70px;
		text-align: center;
		display: block;
		height: 50px;
	}
	.trip-categories .btn:hover {
		cursor: pointer;
	}
	.trip-categories .btn:hover .btns {
		color: #118AB2;
	}
	.trip-categories .btn:hover img.inactive {
		display: none;
	}
	.trip-categories .btn:hover img.active {
		display: block;
	}
	
	/* Notifications (e.g. flight expiry) */
	.notifications {
		font-size: 12px;
		text-align: left;
		padding-left: 0px;
	}
	.notifications .green {
		background-image: url('assets/alerts/alert-green.png');
	}
	.notifications .yellow {
		background-image: url('assets/alerts/alert-yellow.png');
	}
	.notifications .red {
		background-image: url('assets/alerts/alert-red.png');
	}
	.notifications .blue {
		background-image: url('assets/alerts/alert-blue.png');
	}
	.notifications .issue-blue {
		background-image: url('assets/alerts/alert-issue-blue.png');
	}
	.notifications .orange {
		background-image: url('assets/alerts/alert-orange.png');
	}
	.notifications .notifications-inner {
		min-height: 17px;
		padding-left: 20px;
		background-repeat: no-repeat;
		background-position: top left;
		background-size: 16px 16px;
		list-style-type: none;
		margin-bottom: 9px;
	}
	
	/* Trip Item Page Notifications (e.g. flight expiry) */
	.notification-action {
		text-align: center;
	}
	.notification-action .result.icon {
		width: 161px;
		margin: 0 auto;
		margin-bottom: 30px;
		margin-top: 20px;

	}
	.notification-action .title {
		background-repeat: no-repeat;
		background-position: top 50% left;
		background-size: 24px 24px;
		line-height: 24px;
		width: auto;
		display: inline-block;
		padding-left: 30px;
		font-weight: 700;
		font-size: 24px;
		line-height: 29px;
		text-transform: uppercase;
		color: #898F98;
	}
	.notification-action .text {
		font-weight: 400;
		font-size: 12px;
		line-height: 15px;
		color: black;
		margin-top: 10px;
	}
	.notification-action .or {
		font-weight: bold;
		margin-top: 15px;
	}
	.notification-action .other {
		margin-bottom: 15px;
	}
	.notification-action.blue .title {
		background-image: url('assets/alerts/alert-blue.png');
	}
	.notification-action.red .title {
		background-image: url('assets/alerts/alert-red.png');
	}
	.notification-action.yellow .title {
		background-image: url('assets/alerts/alert-yellow.png');
	}
	.notification-action.green .title {
		background-image: url('assets/alerts/alert-green.png');
	}
	.notification-action.blue .button.main {
		background-color: #19BDE1;
	}
	.notification-action.yellow .button.main {
		background-color: #E9C50B;
	}

	/* Booking payment summary table NEW */
	.summary-table {

	}
	.summary-table .heading {
		text-transform: capitalize;
		color: #118AB2;
	}
	.summary-table .section-heading {
		font-weight: 600;
		font-size: 16px;
		color: #000000;
		margin-bottom: 15px;
	}
	.summary-table .item-heading {
		font-weight: 600;
		font-size: 16px;
		color: #118AB2;
		margin-bottom: 10px;
		margin-top: 30px;
	}
	.summary-table .section {
		/* margin-bottom: 30px; */
	}
	.summary-table .table-row {
		display: flex;
		justify-content: space-between;
		min-height: 40px;
		align-items: center;
		color: #000000;
		border-bottom: 1px solid #F2F2F2;
		padding: 5px 10px;
		box-sizing: border-box;
	}
	.summary-table .table-row.warning {
		padding-left: 35px;
		background-image: url('assets/alert-icon.png');
		background-repeat: no-repeat;
		background-position: 10px;
		background-size: 16px;
	}
	.summary-table .table-row.warning .button {
	}
	.summary-table .table-row .button {
		background-color: #118AB2
	}
	.summary-table .table-row .button {
		width: auto;
		margin: 0;
	}
	.summary-table .table-row.big {
		height: 80px;
		font-weight: 700;
		font-size: 28px;
		line-height: 24px;
		color: #000000;
		margin-bottom: 30px;
	}
	.summary-table .table-row .type { 
		border-radius: 10px;
		padding: 1px 4px;
		color: white;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 11px;
		background-color: #7F3D9E;
	}
	.summary-table .table-row .type.flight {
		background-color: #7F3D9E;
	}
	.summary-table .table-row .type.tour {
		background-color: #4CBCC3;
	}
	.summary-table .table-row.bold {
		font-weight: 700;
	}
	.summary-table .table-row.status.yellow {
		background-color: #FAEDD9;
	}
	.summary-table .table-row.status.red {
		background-color: #FFE9E9;
	}
	.summary-table .table-row.status.green {
		background-color: #F3FEF7;
	}
	.summary-table .table-row.status .data {
		border-radius: 10px;
		padding: 1px 6px;
		color: white;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 11px;
		background-color: #DAB90F;
	}
	.summary-table .table-row.status.yellow .data {
		background-color: #DAB90F;
	}
	.summary-table .table-row.status.red .data {
		background-color: #FA5252;
	}
	.summary-table .table-row.status.green .data {
		background-color: #06D6A0;
	}
	.summary-table .button.book {
		display: block;
		margin: 0 auto;
		margin-bottom: 40px;
	}
	.summary-table .table-row .name {

	}
	.summary-table .table-row .data {
		
	}
	/* End Booking payment summary table NEW */

	/* Booking payment summary table OLD */
	table.confirmation {
		margin: 0 auto;
		font-size: 14px;
		width: 100%;
		margin-bottom: 20px;
	}
	table.confirmation td {
		line-height: 28px;
	}
	table.confirmation .value {
		text-align: right;
		font-weight: 600;
		color: #000000;
	}
	table.confirmation .value span {
		font-size: 9px;
	}

	/* eticket number for flights*/
	.res-no {
		font-weight: 700;
		font-size: 16px;
		line-height: 19px;
		text-align: center;

		color: #000000;
		margin-bottom: 25px;
	}
	.res-no .no {
		display: block;
		font-size: 24px;
		line-height: 29px;
		color: #06D6A0;
	}

	/* Pricing table used on ManageTripItemTravellers and Payment views */
	.pricing-table {
		text-align: left;
		/* max-width: 350px; */
		margin: 0 auto;
		vertical-align: middle;
		font-size: 14px;
		line-height: 29px;
	}
	.pricing-table .pax-icon {
		margin-right: 5px;
	}
	.pricing-table .pax-icon {
		width: 29px;
		vertical-align: middle;
	}
	.pricing-table .price {
		float: right;
	}
	.pricing-table .total {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		margin-top: 10px;
	}
	.pricing-table .total .total-value {
		float: right;
		font-size: 20px;
	}
	.pricing-table {
		margin-bottom: 30px;
	}
	.pricing-table .alert-icon {
		width: 14px;
		vertical-align: middle;
	}
	.pricing-table .text {
		width: 14px;
		vertical-align: middle;
	}

	/* Carbon offset on flights pages */
	.carbon-section {
		margin-bottom: 20px;
	}
	.carbon-section .carbon {
		display: block;
		margin: 0 auto;
		text-align: center;
		margin-top: 50px;
		margin-bottom: 18px;
		border-radius: 16px;
		height: 150px;
		max-width: 320px;
		background-size: 320px auto;
		background-repeat: no-repeat;
		text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
	}
	.carbon-section .carbon span {
		display: inline-block;
		width: 280px;
		margin-top: 26px;
		font-size: 20px;
		font-weight: 700;
		color: #ffffff;
	}
	.carbon-section .carbon .total {
		display: inline-block;
		margin-top: 18px;
		margin-bottom: 17px;
		font-size: 34px;
	}
	.carbon-section .carbon .total .unit {
		display: inline;
		font-size: 18px;
	}
	.carbon-section .points {
		width: 300px;
		display: block;
		margin: 0 auto;
		text-align: center;
		font-size: 14px;
		font-weight: 400;
		line-height: 24px;
		min-height: 40px;

		background-image: url('assets/carbon/footprint-icon.png');
		background-repeat: no-repeat;
		background-position: left center;
		background-size: 28px;
		padding-left: 30px;
	}
	.carbon-section .points span {
		color: #06D6A0;
		font-weight: 800;
	}
	.carbon-section .points span.i {
		position: absolute;
		border: 1px solid #008BB5;
		border-radius: 50%;
		display: inline-block;
		width: 8px;
		height: 8px;
		margin-left: 2px;
		font-size: 8px;
		font-weight: 800;
		line-height: 8px;
		color: #008BB5;
	}

	/* Toaster Alerts */
	.v-toast {
		padding: 10px 10px !important;
	}
	.v-toast__item {
		width: 338px;
		max-width: 100%;
		min-height: 50px !important;
		height: auto !important;
		position: relative;
		background-color: white !important;
		border-radius: 10px !important;

		background-repeat: no-repeat !important;
		background-position: right 13px top 50% !important;
		background-size: 18px !important;
		padding-top: 5px;
		padding-bottom: 5px;

		z-index: 1;
	}
	.v-toast__item .v-toast__icon {
		position: relative;
		z-index: 10;
		width: 18px !important;
		height: 18px !important;
		min-width: 18px !important;
	}
	.v-toast__item .v-toast__text {
		width: 263px;
		padding: 5px 45px 5px 15px !important;
		width: 100%;
		/* position: absolute;
		top: 0; left: 0; right: 0; */
		box-sizing: border-box;
		font-family: 'Inter';
		font-weight: 700;
		font-size: 12px;
		line-height: 1.2;
		border-radius: 10px !important;
	}
	.v-toast__item .v-toast__text .message {
	}

	.v-toast__item:before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		border-radius: 10px !important;
	}

	.v-toast__item--success {
		background-image: url('assets/toaster/success-exit.png');
		border-color: #06D6A0;
	}

	.v-toast__item--success:before {
		background: linear-gradient(90deg, #06D6A0 0%, rgba(6, 214, 160, 0.5) 100%);
	}

	.v-toast__item--warning {
		background-image: url('assets/toaster/warning-exit.png');
		border-color: #E9C50B;
	}

	.v-toast__item--warning:before {
		background: linear-gradient(90deg, #E9C50B 0%, rgba(233, 197, 11, 0.5) 100%);
	}

	.v-toast__item--error {
		background-image: url('assets/toaster/error-exit.png');
		border-color: #FA5252;
	}

	.v-toast__item--error:before {
		background: linear-gradient(90deg, #FA5252 0%, rgba(250, 82, 82, 0.5) 100%);
	}

	.v-toast__item--info {
		background-image: url('assets/toaster/info-exit.png');
		border-color: #118AB2;
	}

	.v-toast__item--info:before {
		background: linear-gradient(89.89deg, #118AB2 0.08%, rgba(17, 138, 178, 0.5) 99.91%);
	}
	.v-toast__item.v-toast__item--error .v-toast__icon {
		background: url('assets/toaster/error-icon.png') !important;
		background-size: 18px 18px !important;
	}
	.v-toast__item.v-toast__item--success .v-toast__icon {
		background: url('assets/toaster/success-icon.png') !important;
		background-size: 18px 18px !important;
	}
	.v-toast__item.v-toast__item--info .v-toast__icon {
		background: url('assets/toaster/info-icon.png') !important;
		background-size: 18px 18px !important;
	}
	.v-toast__item.v-toast__item--warning .v-toast__icon {
		background: url('assets/toaster/warning-icon.png') !important;
		background-size: 18px 18px !important;
	}

	/* Page transitions */
	.fade-enter-active,
	.fade-leave-active {  
		position: absolute; left: 0; top: 0; right: 0;
		transition: opacity 0.2s ease !important;
		opacity: 0 !important;
	}
	.fade-enter-from {
		opacity: 0 !important;
	}
	.fade-leave-to {  
		opacity: 0 !important;
	}
	.fade-enter-to {
		opacity: 1 !important;
	}
	.fade-leave-from {  
		opacity: 1 !important;
	}

	.slide-right-enter-active {
		/* position: absolute; left: 0; right: 0; */
		transition: all 0.3s ease !important;
		transform: translateX(100%);
		opacity: 0 !important;
	}
	.slide-left-enter-active {
		/* position: absolute; left: 0; right: 0; */
		transition: all 0.3s ease !important;
		transform: translateX(-100%);
		opacity: 0 !important;
	}
	.slide-right-leave-active,
	.slide-left-leave-active {  
		position: absolute; left: 0; top: 0; right: 0;
		transition: all 0.3s ease !important;
		transform: translateX(0%);
		opacity: 0 !important;
	}
	.slide-right-enter-to,
	.slide-left-enter-to {  
		transform: translateX(0%) !important;
		opacity: 1 !important;
	}
	.slide-right-enter-from,
	.slide-left-leave-to {  
		transform: translateX(100%) !important;
		opacity: 0 !important;
	}
	.slide-right-leave-to,
	.slide-left-enter-from {  
		transform: translateX(-100%) !important;
		opacity: 0 !important;
	}
	.slide-right-leave-from,
	.slide-left-leave-from {  
		transform: translateX(0%) !important;
		opacity: 1 !important;
	}

	/* Search results */
	.results {
		/* overflow: hidden; */
		width: 375px;
		max-width: 100%;
		padding: 0px 1px;
		box-sizing: border-box;
		margin: 0 auto;
		padding-bottom: 75px;
	}
	.results .search-data {
		text-align: center;
		padding: 30px 10px 0px 10px;
		width: 100%;
		box-sizing: border-box;
	}
	.results .search-data .dates,
	.results .search-data .locations {
		margin: 0px;
		width: 100%;
		height: 45px;
		border: solid 1px rgba(60, 60, 60, 0.26);
		border-radius: 10px;
		line-height: 45px;
		margin-bottom: 8px;
		box-sizing: border-box;
		cursor: pointer;
	}
	.tours.results .search-data .locations .keyword {
		padding-left: 45px;
		background-position: left 16px center;
		background-repeat: no-repeat;
		background-size: 20px auto;
		float: left;
		text-align: left;
		box-sizing: border-box;
	}
	.results .search-data .date-select.end {
		margin-right: 0;
	}
	.results .search-data .start,
	.results .search-data .end {
		display: inline-block;
		width: 170px;
		max-width: 45%;
		text-align: left;
		padding-left: 15px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		overflow: hidden;
		max-height: 100%;
		white-space: nowrap;
	}
	.results .search-data .locations .start,
	.results .search-data .locations .end {
		padding-left: 45px;
		background-position: left 16px center;
		background-repeat: no-repeat;
		background-size: 20px auto;
	}

	.results .search-data .center-icon {
		width: 27px;
		position: relative;
		z-index: 100;
		top: 9px;
		vertical-align: top;
	}
	.results .search-data .other {
		font-size: 12px;
		font-weight: 600;
		color: #50555c;
		margin: 10px 0px 5px 0px;
		text-align: center;
		cursor: pointer;
	}
	.results .search-data .other .details-icon {
		margin-right: 5px;
	}
	.results .search-data .other .details-icon,
	.results .search-data .other .pax,
	.results .search-data .other .class {
		vertical-align: middle;
	}
	.results .search-data .other .edit:hover {
		cursor: pointer;
	}
	.results .options {
		margin-top: 10px;
		padding: 0px 10px;
	}


	.flights.results .sort-wrapper {
		width: 100%;
		background-color: #118AB2;
		margin-bottom: 7px;
		margin-top: 10px;
		text-align: center;
	}
	.flights.results .sort {
		display: inline-flex;
		justify-content: space-between;
		width: 375px;
		max-width: 100%;
		padding: 12px 20px 9px 20px;
		box-sizing: border-box;
		background-color: #118AB2;
		color: white;

	}
	.flights.results .sort .section {
		display: inline-block;
		margin: 0 10px;
		vertical-align: top;
		min-width: 55px;
		text-align: left;
		cursor: pointer;
	}
	.flights.results .sort .section .price {
		border-bottom: 2px solid #7C94B9;
	}
	.flights.results .sort .section.selected .price {
		border-bottom: 2px solid white;
	}
	.flights.results .sort .section:first-of-type {
		margin-left: 0px;
	}
	.flights.results .sort .section img {
		display: inline-block;
		height: 16px;
		float: right;
	}

	.flights.results .sort .section .type {
		font-size: 12px;
		font-weight: 600;
	}
	.flights.results .sort .section .price {
		font-size: 20px;
		font-weight: 700;
	}
	.flights.results .sort .section .travel-time {
		font-size: 10px;
		margin-left: 5px;
	}

	.results .errors {
		width: 100%;
		text-align: center;
		padding-top: 50px;
	}
	.results .caption {
		font-size: 12px;
		margin-bottom: 10px;
		margin-top: 5px;
	}
	.results .button {
		margin-top: 20px;
	}
	.results .main-text {
		color: #898F98;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 24px;
	}
	.results .icon {
		margin: 0 auto;
	}
	.results .filter-results * {
		vertical-align: middle;
		font-size: 12px;
		line-height: 15px;
		font-weight: 600;
		color: #50555C;
	}
	.results .filter-results .plane-icon {
		margin-right: 5px;
	}
	.results .filter-results .filter-icon {
		float: right;
	}
	.results .results .filter-results * {
		vertical-align: middle;
		font-size: 12px;
		line-height: 15px;
		font-weight: 600;
		color: #50555C;
	}
	.results .results .filter-results .plane-icon {
		margin-right: 5px;
	}
	.results .results .filter-results .filter-icon {
		float: right;
	}

	/* Filter options */
	.filters .heading-section .icon {
		vertical-align: middle;
		margin-right: 8px;
	}
	.filters .heading-section .heading6 {
		display: inline-block;
		vertical-align: middle;
	}
	.filters .main-text .title {
		font-size: 16px;
		font-weight: 400;
	}
	.filters .main-text .sub {
		font-size: 12px;
		color: #50555C;
	}
	.filters {
		position: relative;
		min-height: calc(100vh - 85px);
	}
	.filters .main {
		margin-bottom: 90px;
	}
	.filters .button {
		margin: 0 auto;
	}
	.filters .bottom {
		box-sizing: border-box;
		text-align: center;
		position: absolute;
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);
	}
	/* End filters */

	/* Fare card */
	.fare-card {
		position: relative;
		background: #FCFCFE;
		border: 1px solid #E5E5E5;
		box-shadow: 0px 2px 2px rgba(204, 204, 204, 0.1);
		border-radius: 0px 10px 10px 0px;
		background-color: #FCFCFE;
		font-size: 14px;
		padding: 15px 18px 30px 18px;
		border-left: 3px solid #0D90EF;
		margin-bottom: 20px;
		max-width: 320px;
	}

	.fare-card .button-holder .button {
		display: block;
		margin: 0 auto;
	}
	.fare-card .button-holder .button.select,
	.fare-card .button-holder .button.change {
		background-color: white;
		color: #118AB2;
		border: 1px solid #118AB2
	}
	.fare-card .button-holder .button.change {
		margin-top: 20px;
	}

	/* End fare card */

	/* Flight card & option components shared styles */
	.logo-container.default-logo {
		position: relative;
	}
	.logo-container .carrier-code {
		display: none;
	}
	.logo-container.default-logo .carrier-code {
		position: absolute;
		top: 0;
		left: -3px;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
		color: #333;
		font-size: 8px;
		transform: rotate(60deg);
	}
	.flight-opt-row {
		display:block;
	}
	.flight-opt-row .flight-details {
		display: flex;
		width: 100%;
		align-items: center;
	}

	.flight-opt-row .flight-details .airline-logo {
		width: 36px;
		object-fit: contain;
	}
	.flight-opt-row .flight-details .detail {
		margin: 0 5px;
		color: #000000;
	}
	.flight-opt-row .flight-details .detail .date {
		font-weight: 400;
		font-size: 11px;
		color: #161D27;
	}
	.flight-opt-row .flight-details .detail .time {
		font-weight: 600;
		font-size: 16px;
	}
	.flight-opt-row .flight-details .detail .airport {
		font-weight: 600;
		font-size: 11px;
		line-height: 13px;
		font-weight: 600;

		color: #50555C;
	}
	.flight-opt-row .flight-details .journey {
		flex-grow: 2;
		text-align: center;
		height: 45px;
	}
	.flight-opt-row .flight-details .journey .journey-length {
		display: inline-block;
		font-weight: 600;
		font-size: 11px;
		line-height: 24px;
		text-align: center;
		background-image: url('assets/trip/duration-icon.png');
		background-repeat: no-repeat;
		background-position: left;
		background-size: 11px;
		padding-left: 15px;
	}
	.flight-opt-row .flight-details .journey .line {
		position: relative;
		margin-bottom: 5px;
	}
	.flight-opt-row .flight-details .journey .line .small-line {
		width: 100%;
		height: 1px;
		background-color: #C4C4C4;
		/* border-top: 1px solid #C4C4C4; */
	}
	.flight-opt-row .flight-details .journey .ball {
		width: 6px;
		height: 6px;
		background: #C4C4C4;
		border-radius: 4px;
		position: absolute;
		top: -2px;
	}
	.flight-opt-row .flight-details .journey .ball.start {
		left: 0;
	}
	.flight-opt-row .flight-details .journey .ball.end {
		right: 0;
	}
	.flight-opt-row .flight-details .journey .layover-balls {
		height: 6px;
		top: -2px;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		justify-content: space-evenly;
		max-width: 80%;
		min-width: 50%;
	}
	.flight-opt-row .flight-details .journey .layover-balls .ball {
		position: unset;
		background-color: #118AB2;
		margin: 0 2px;
	}
	.flight-opt-row .flight-details .journey .layovers {
		font-weight: 600;
		font-size: 11px;
		line-height: 12px;
		color: #118AB2;
		text-align: center;
	}
	.flight-opt-row .airline-name {
		font-weight: 400;
		font-size: 11px;
		line-height: 13px;
		color: #50555C;
	}
	.flight-opt-row .airline-name .flight-number {
		text-align: right;
		float: right;
		background-image: url('assets/trip/flights.png');
		background-repeat: no-repeat;
		background-position: left;
		background-size: 11px;
		padding-left: 15px;
	}
</style>
