<template>
<div>
	<header class="outer-wrapper" v-if="!settings.hasOwnProperty('hide') || !settings.hide" :class="settings.overlayContent ? 'overlayContent' : ''">
		<a class="banner" href="https://purpose.travel/investors">Investors Information</a>
		<nav id="top-nav">
			<!-- Left Icon -->
			<TopLeftIcon icon="main" v-if="settings.left.type == 'home' || (settings.left.type == 'back' && (settings.left.route == '/' || settings.left.route.name == 'home'))" /> 

			<div v-else-if="settings.left.type == 'back'">
				<div v-if="settings.left.route == '/' || settings.left.route.name == 'home'">

				</div>
				<router-link :to="settings.left.route">
					<img v-if="settings.left.color == 'white'" src="@/assets/back-icon-white.png" alt="back" class="back-icon"/>
					<img v-else src="@/assets/back-icon.png" alt="back" class="back-icon"/>
				</router-link>
			</div>

			<!-- Right Icon -->
			<Menu v-if="settings.right.type == 'menu'" />

			<div v-else-if="settings.right.type == 'exit'">
				<router-link :to="settings.right.route ? settings.right.route : { name: 'Home' }">
					<img src="@/assets/close-icon.png" alt="close" class="close-icon"/>
				</router-link>
			</div>
		</nav>
	</header>
</div>
</template>

<script>
	import Menu from '@/components/Menu.vue'
	import TopLeftIcon from '@/components/TopLeftIcon.vue'

	export default {
		props: {
			settings: {
				type: Object,
				default: () => {},
			},
		},
		components: {
			Menu,
			TopLeftIcon,
		},
	}
</script>

<style scoped>
	.overlayContent {
		height: 0;
		overflow: visible;
	}

	.banner {
		display: block;
		background-color: #118AB2;
		text-align: center;
		color: white;
		padding: 15px;
		font-weight: bold;
		text-decoration: underline;
	}
</style>