import apiClient from '@/helpers/axios.js';
import { store } from '../store/index';

export const userService = {
	login,
	reauthenticate,
	googleLogin,
	logout,
	register,
	checkEmail,
	facebookLogin,
	resetPassword,
	updatePassword,
	adminLoginOveride,

	saveUserProfile,
	fetchUserProfile
};

// Authentication
function login(data) {
	return apiClient.post('/login', data);
}
function reauthenticate(data) {
	return apiClient.post('/reauth_user', data, {
		headers: store.getters['account/getAuthHeader']
	});
}
function googleLogin(data) {
	return apiClient.post('/google_login', JSON.stringify(data));
}
function facebookLogin(data) {
	return apiClient.post('/facebook_login', JSON.stringify(data));
}
function logout() {
	localStorage.removeItem('user');
}
function register(user) {
	return apiClient.post('/register_user', user);
}
function checkEmail(email) {
	return apiClient.post('/check_email', { email: email });
}
function resetPassword(data) {
	return apiClient.post('/reset_password', data);
}
function updatePassword(data) {
	return apiClient.post('/submit_new_password', data);
}
function adminLoginOveride(user_id) {
	return apiClient.post('/admin_login_overide', { user_id: user_id }, {
		headers: store.getters['account/getAuthHeader']
	});
}

// Profile Data
function saveUserProfile(data) {
	let formData = new FormData();
	
	for(const name in data) { // data that aren't images
		if(name != "profile_image" && name != "banner_image") {
			formData.append(name, data[name]);
		}
	}
	if(data.profile_image) { // Images here
		formData.append('profile_image', data.profile_image, data.profile_image.name);
	}
	if(data.banner_image) {
		formData.append('banner_image', data.banner_image, data.banner_image.name);
	}

	

	let headers = store.getters['account/getAuthHeader'];
	headers['Content-Type'] = "multipart/form-data";
	return apiClient.post('/save_user_profile', formData, {
		headers: headers,
	});
}
function fetchUserProfile() {
	return apiClient.get('/fetch_user_profile', {
		headers: store.getters['account/getAuthHeader']
	});
}