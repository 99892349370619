import { searchService } from '@/services/searchService.js'
import helpers from '@/helpers/helpers.js';
import router from '@/router'
import axios from 'axios'; // Used for passing cancelTokens to requests
import track from '@/helpers/track.js'

const today = new Date();
let defaultEndDate = today.setDate(today.getDate() + 7);
defaultEndDate = new Date(defaultEndDate);

const state = {
	isMapped: false, // Marker that searchValues has/haven't been set by user - for query params syncing
	searchValues: {
		keyword: "",
		dates: { start: new Date(), end: defaultEndDate }, // Date selection (when user select selectSpecificDates marker)
		qty: {
			adults: 1,
			children: 0,
		},

		// directOnly: false,
	},
	
	searchResults: [],
	otherSearchResults: [],
	filteredSearchResults: [],

	searchResultsErrors: null,
	isLoading: false,
	shouldSearch: true,

	// Filter options are a get function that determines the options based on the search results.
	searchFilters: { // Null = no filter applied (all options selected on frontend)
		priceRange: { low: false, high: false }, // time of day in seconds
		duration: { low: false, high: false }, // Duration in days
		startLocations: {}, // {'Bangkok': true, 'Phuket': false, 'Koh Samui': true}
		endLocations: {}, // {'Bangkok': true, 'Phuket': false, 'Koh Samui': true}
	},

	cancelToken: false, // If set, this can be used to cancel the request that set it. Used to prevent duplicate searches overwriting each other.
}

const getters = {
	formattedDates(state) {
		if(state.searchValues.dates) {
			if(state.searchValues.dates.start) { // Is return 
				var start = helpers.formatDateISO(new Date(state.searchValues.dates.start))
					if(state.searchValues.dates.end != "" && state.searchValues.dates.end != null) {
						var end = helpers.formatDateISO(new Date(state.searchValues.dates.end));
					} else {
						var end = "";
					}
				var date = { start: start, end: end };
			} else if(state.searchValues.dates.start == ""){
				var start = helpers.formatDateISO(new Date());
				var date = { start: start, end: "" };
			} else {
				var start = helpers.formatDateISO(new Date(state.searchValues.dates));('DD-MM-YYYY');
				var date = { start: start, end: "" };
			}
		} else {
			var date = { start: helpers.formatDateISO(new Date()), end: "" };
		}
		return date;
	},
	monthsAsString(state) {
		let string = "";
		var years = Object.keys(state.searchValues.months);
		for(var year of years) {
			let monthsArray = state.searchValues.months[year];
			if(monthsArray.length > 0) {
				monthsArray = monthsArray.map(month => { return helpers.toMonthName(month) });
				let currentYearString = year + ": " + monthsArray.join(', ');
				string += currentYearString + ". ";
			}
		}
		return string;
	},
	totalPax() {
		return parseInt(state.searchValues.qty.adults) + parseInt(state.searchValues.qty.children) + parseInt(state.searchValues.qty.infants);
	},
	searchFormErrors(state, getters) {
		let errors = new Map();
		let dateErrors = [];

		
		if(getters.formattedDates.start == null || getters.formattedDates.start == "") {
			dateErrors.push('You must select a start date');
		}
		if(getters.formattedDates.end == null || getters.formattedDates.end == "") {
			dateErrors.push('You must select an end date');
		}
	
		if(dateErrors.length > 0) {
			errors.set('dates', dateErrors);
		}

		let keywordErrors = [];
		if(state.searchValues.keyword == null || state.searchValues.keyword == "") {
			keywordErrors.push('You must enter a keyword search');
		}
		if(keywordErrors.length > 0) {
			errors.set('keyword', keywordErrors);
		}

		let paxErrors = [];
		let totalQty = state.searchValues.qty.adults + state.searchValues.qty.children + state.searchValues.qty.infants;
		// Do we need validation to ensure 1 passenger is an adult? Or at least no infants travelling without an adult? 
		if(totalQty == 0) {
			paxErrors.push('You must have at least 1 traveller');
		}
		// if(state.searchValues.qty.infants > state.searchValues.qty.adults) {
		// 	paxErrors.push('You must have at least 1 adult per infant');
		// }
		if(paxErrors.length > 0) {
			errors.set('pax', paxErrors);
		}
		return errors;
	},
	// Based on the search results, get filter options (e.g. options that are relevant to the search, so that we don't display irrelevant options).
	// This means, the default VALUES of the filters will be based on the search results, not which filters are shown.
	getFilterOptions(state) {
		let options = {
			startLocations: {},
			endLocations: {},
			priceRange: { low: false, high: false },
			duration: { low: false, high: false },
	
		};

		if(Array.isArray(state.searchResults)) {
			for (const [key, result] of (state.searchResults)) {
				// Start Locations
				let startLocation = result.start_location; 
				let startLocationFromPrice = parseInt(result.advertised_price.find((el) => { return el.currency == 'GBP' }).amount);
				if(!options.startLocations.hasOwnProperty(startLocation)) { // Doesn't exist already
					options.startLocations[startLocation] = {
						name: startLocation,
						fromPrice: startLocationFromPrice,
					};
				} else if(parseInt(options.startLocations[startLocation].fromPrice) > parseInt(startLocationFromPrice)) {
					options.startLocations[startLocation].fromPrice = startLocationFromPrice; // If already exists but has higher fromPrice, update price.
				}

				// End Locations
				let endLocation = result.end_location ? result.end_location : result.start_location; 
				let endLocationFromPrice = parseInt(result.advertised_price.find((el) => { return el.currency == 'GBP' }).amount);
				if(!options.endLocations.hasOwnProperty(endLocation)) { // Doesn't exist already
					options.endLocations[endLocation] = {
						name: endLocation,
						fromPrice: endLocationFromPrice,
					};
				} else if(parseInt(options.endLocations[endLocation].fromPrice) > parseInt(endLocationFromPrice)) {
					options.endLocations[endLocation].fromPrice = endLocationFromPrice; // If already exists but has higher fromPrice, update price.
				}

				// Price range
				let price = Math.ceil(result.advertised_price.find((el) => { return el.currency == 'GBP' }).amount);
				if(!options.priceRange.high || (Math.ceil(options.priceRange.high) <= price)) {
					options.priceRange.high = price;
				}
				if(!options.priceRange.low || (Math.floor(options.priceRange.low) >= price)) {
					options.priceRange.low = price;
				}

				// Duration in days
				let duration = result.length;
				if(!options.duration.high || (Math.ceil(options.duration.high) <= duration)) {
					options.duration.high = duration;
				}
				if(!options.duration.low || (Math.floor(options.duration.low) >= duration)) {
					options.duration.low = duration;
				}
			}
		}

		return options;
	}
}

const mutations = {
	SET_SEARCH_RESULTS(state, results) {
		state.searchResults = Object.entries(results.results);
		state.otherSearchResults = Object.entries(results.date_unmatched);
	},	
	SET_FILTERED_SEARCH_RESULTS(state, results) {
		state.filteredSearchResults = results;
	},	
	SET_SEARCH_RESULTS_ERRORS(state, errors) {
		state.searchResultsErrors = errors;
	},
	SET_SEARCH_RESULTS_LOADING(state, loading) {
		state.isLoading = loading;
	},
	SET_SHOULD_SEARCH(state, value) {
		state.shouldSearch = value;
	},
	SET_QTY(state, params) {
		state.searchValues.qty.adults = params.adults;
		state.searchValues.qty.children = params.children;
	},
	SET_KEYWORD(state, keyword) {
		state.searchValues.keyword = keyword;
	}
}
  
const actions = {
	submitTourSearch({ commit, getters, dispatch, state }) {
		// Check for the correct 'type' property
		if(!state.searchValues.keyword.hasOwnProperty('type') && state.searchValues.keyword.hasOwnProperty('location_type')) {
			// console.log(state.searchValues.keyword);
			state.searchValues.keyword.type = state.searchValues.keyword.location_type;
		}
		if(state.cancelToken) { // If there is a cancel token, then use it to cancel any previous request.
			state.cancelToken.cancel('cancel');
		}
		if(!state.shouldSearch) {
			dispatch('updateFilteredResults'); // Always do this regardless
			return false; // Don't conduct the search
		}
		let data = {};
		if(state.searchValues.keyword.type == "keyword") {
			// Keyword search
			data.tour = {
				// tour_id: state.searchValues.keyword.tour_id,
				name: state.searchValues.keyword.name,
				type: "tour",
			}
		} else if(state.searchValues.keyword.type == "tour") {
			// Specific tour
			data.tour = {
				tour_id: state.searchValues.keyword.tour_id,
				type: "tour",
			}
 		} else {
			// Location
			data.location = {
				code: state.searchValues.keyword.code,
				type: state.searchValues.keyword.type,
			};
		}

		data.dates = state.searchValues.dates;
		data.dates.start = new Date(data.dates.start);
		data.dates.end = new Date(data.dates.end);

		commit('SET_SEARCH_RESULTS_ERRORS', null);
		commit('SET_SEARCH_RESULTS_LOADING', true);

		const CancelToken = axios.CancelToken;
		state.cancelToken = CancelToken.source(); // Set the cancel token for this request into state, so we can cancel it later if needed.

		try {
			track.event('search', {
				searchType: 'tour',
				startLocation: data.location ? data.location.code : null,
				tourID: data.tour && data.tour.tour_id ? data.tour.tour_id : null,
				keyword: data.tour && data.tour.name ? data.tour.name : null,
				outboundDate: data.dates.start.toISOString(),
				returnDate: data.dates.end.toISOString(),
			});
		} catch(e) {
			console.error(e);
			this.$rollbar.error("Tracking: " + e);
		}

		searchService.submitTourSearch(data, state.cancelToken.token)
			.then(response => {
				if(response) {
					if(response.data.success){
						let result = response.data.data;
					
						commit('SET_SEARCH_RESULTS', result);
						commit('SET_SEARCH_RESULTS_LOADING', false);
						dispatch('updateFilteredResults');
					} else {
						commit('SET_SEARCH_RESULTS_ERRORS', "Something went wrong.");
						commit('SET_SEARCH_RESULTS_LOADING', false);
						dispatch('updateFilteredResults');
					}
					commit('SET_SHOULD_SEARCH', false);
				}
			},
			error => {
				commit('SET_SEARCH_RESULTS_ERRORS', error);
				commit('SET_SEARCH_RESULTS_LOADING', false);
				dispatch('updateFilteredResults');
				commit('SET_SHOULD_SEARCH', false);
			});
			
	},
	// Applies the filters to the results and stores them in filteredResults
	updateFilteredResults({ commit, getters }) {
		// // For "StartLocations" filter - New options based on most recent search need to be set to true by default.
		if(state.searchFilters.startLocations == null) {
			state.searchFilters.startLocations = {};
		}
		for(let index in getters.getFilterOptions.startLocations) {
			let option = getters.getFilterOptions.startLocations[index];
			if(state.searchFilters.startLocations[option.name] == undefined) { // ONLY IF the filter option is new, default it to true.
				state.searchFilters.startLocations[option.name] = true;
			}
		}

		// // For "EndLocations" filter - New options based on most recent search need to be set to true by default.
		if(state.searchFilters.endLocations == null) {
			state.searchFilters.endLocations = {};
		}
		for(let index in getters.getFilterOptions.endLocations) {
			let option = getters.getFilterOptions.endLocations[index];
			if(state.searchFilters.endLocations[option.name] == undefined) { // ONLY IF the filter option is new, default it to true.
				state.searchFilters.endLocations[option.name] = true;
			}
		}

		// Filter the searchResults based on filters
		let searchResultsClone = JSON.parse(JSON.stringify(state.searchResults)); // Clone here because we change data inside the filter. Re-filter will then use original data again.
		let filteredResults = searchResultsClone.filter((element) => {
			// Price Range
			let priceIncluded = false;
			let price = Math.ceil(element[1].advertised_price.find((el) => { return el.currency == 'GBP';}).amount);
			if(!state.searchFilters.priceRange.low && !state.searchFilters.priceRange.high) { // Not set by the user, include it
				priceIncluded = true;
			} else if(price >= Math.floor(state.searchFilters.priceRange.low) && price <= Math.ceil(state.searchFilters.priceRange.high)) {
				priceIncluded = true;
			}

			// Duration range
			let durationIncluded = false;
			let duration = parseInt(element[1].length);
			if(!state.searchFilters.duration.low && !state.searchFilters.duration.high) { // Not set by the user, include it
				durationIncluded = true; // Filter not set by user, include it
			}
			if(duration >= parseInt(state.searchFilters.duration.low) && duration <= parseInt(state.searchFilters.duration.high)) {
				durationIncluded = true; // Fits the filter
			}

			// StartLocation
			let startLocation = element[1].start_location;
			let startLocationIncluded = true; // This remains false if no stops filter option is hit
			// If the outbound carrier is not a selected airline, remove it from the search results
			if(!state.searchFilters.startLocations[startLocation]) {
				startLocationIncluded = false;
			}

			// endLocation
			let endLocation = element[1].end_location ? element[1].end_location : element[1].start_location;
			let endLocationIncluded = true; // This remains false if no stops filter option is hit
			// If the outbound carrier is not a selected airline, remove it from the search results
			if(!state.searchFilters.endLocations[endLocation]) {
				endLocationIncluded = false;
			} 

			let included = priceIncluded && durationIncluded && startLocationIncluded && endLocationIncluded;
			return included;
		});
		commit('SET_FILTERED_SEARCH_RESULTS', filteredResults);

	},
	getTour({ commit }, { tourID }) {
		return searchService.getTour(tourID);
	},
	getTourDepartures({ commit }, { tourID }) {
		return searchService.getTourDepartures(tourID);
	},
	getTourDeparture({ commit }, { tourID, departureID }) {
		return searchService.getTourDeparture(tourID, departureID);
	},
	setQty({ commit }, { adults, children }) {
		commit("SET_QTY", { adults, children });
	},
	setKeyword({ commit }, keyword) {
		commit("SET_KEYWORD", keyword);
	},
	mapQueryParams({}) {
		// console.log(router.currentRoute.query);
		// console.log(state.isMapped);
		if(state.isMapped) { 
			// console.log("Pushing state to query params")
			// Map state to query params - keep query params up to date
			// Foreach searchValues attribute, create query value 
			let query = { ...router.currentRoute.query };
			// console.log(query);
			Object.keys(state.searchValues).forEach(key => {
				if(state.searchValues[key] !== undefined) {
					query[key] = encodeURIComponent(JSON.stringify(state.searchValues[key]));
				}
			})
			// Add to route
			if(JSON.stringify(query) !== JSON.stringify(router.currentRoute.query) ) { // Only replace the route if the query params have changed - prevents a duplicate navigation error
				router.replace({ // Replace not push, to prevent having to press back multiple times to get back to the previous page
					name: router.currentRoute.name,
					query: query,
				});
			}
		} else { // This only happens on refresh (isMapped not yet true)
			// console.log("Mapping query params to state")
			// Map query params to state
			Object.keys(router.currentRoute.query).forEach(key => {
				// console.log(key);
				if(Object.keys(state.searchValues).includes(key)) { // Only if it's a searchValue query param (not unrelated)
					if(state.searchValues[key] !== undefined) {
						state.searchValues[key] = JSON.parse(decodeURIComponent(router.currentRoute.query[key]));
					}
				}
			
			})
		}
		// Set isSet to true (always) - to prevent query -> state mapping from happening again
		state.isMapped = true;
	}
}
  
export const toursSearch = {
    namespaced: true,
	state,
	getters,
    actions,
    mutations
};