import apiClient from '@/helpers/axios.js';

export const searchService = {
	fetchLocationOptions,
	submitFlightSearch,
	getFlights,
	submitTourSearch,
	getCountryTours,
	getTour,
	getTourDepartures,
	getTourDeparture,
};

/**
 * Fetches list of location options based on a search query
 *
 * @param {string} query the search query
 * @param {string} type the type of location to query. Defaults to "all". Options are countries/cities/airports/all/tours.
 * @param {return} return returns a promise containing the list of matching locations
*/
function fetchLocationOptions(query, type = "all") {
	if(type == "tours") { // Tours has it's own specific endpoint. 
		return apiClient.get('/tours/filter/'+ query);
	}
	return apiClient.get('/locations/' + type + '/' + query);
}

// Flights
function submitFlightSearch(data, cancelToken) {
	return apiClient.post('/search', data, { cancelToken: cancelToken })
		.then(response => {
				if(response) {
					if(response.data.hasOwnProperty("error_des")) {
						return Promise.reject(response.data.error_des);
					}
				}

				return response; 
			}, error => {
				return Promise.reject(error);
			}
		)
}
function getFlights(outboundKey, returnKey = false) {
	let queryString = returnKey ? outboundKey + '/' + returnKey : outboundKey;
	return apiClient.post('/search/result/' + queryString);
}

// Tours
function submitTourSearch(data, cancelToken) {
	return apiClient.post('/tours/search', data, { cancelToken: cancelToken })
		.then(response => {
			if(response) {
				// if(response.data.hasOwnProperty("error_des")) {
				// 	return Promise.reject(response.data.error_des);
				// }
			}
			return response;
		}
	);
}
function getCountryTours(countryCode) {
	return apiClient.post('/tours/list/' + countryCode);
}
function getTour(tourID) {
	return apiClient.post('/tours/' + tourID);
}
function getTourDepartures(tourID) {
	return apiClient.post('/tours/' + tourID + '/departures');
}
function getTourDeparture(tourID, departureID) {
	return apiClient.post('/tours/' + tourID + '/departures/' + departureID);
}