import Vue from "vue";
import defaultCarrierLogo from '@/assets/flights-details/airline-default.png';

export default {

	/**
	 * Takes a carrier logo code and returns the path to the logo. Can be used in src for img tags.
	 *
	 * @param {num} date the date to format
	 * @param {string} dateString the formatted date in format: Mon 31 May 2021
	 * @public This is a public method
	*/
	getCarrierLogoPath(code) {
		if(code) {
			// Get env variable for backend url
			const baseUrl = process.env.VUE_APP_BASE_URL;
			const fileName = code.toLowerCase() + ".png";

			return baseUrl + "/images/logo/" + fileName;
		}
	},
	/*
	 * Error event on an img tag. Replaces the src with a default logo. 
	*/
	setDefaultCarrierLogo(event) {
		if (event.target.src !== this.defaultLogo) {
			event.target.src = defaultCarrierLogo;
			event.target.onerror = null;
			event.target.parentNode.classList.add('default-logo'); // Add this class so that we can display the default text
		}
	},

	/**
	 * Turns a JS timestamp (unix * 1000) into a string date.
	 *
	 * @param {num} date the date to format
	 * @param {string} dateString the formatted date in format: Mon 31 May 2021
	 * @public This is a public method
	*/
	formatDate: date => {
		if (date == "") {
			return "";
		}
		const monthArr = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec"
		];
		const dayArr = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
		var date = new Date(date);
		var dateString =
			dayArr[date.getDay()] +
			" " +
			date.getDate() +
			" " +
			monthArr[date.getMonth()] +
			" " +
			date.getFullYear();

		return dateString;
	},
	/**
	 * Turns a JS timestamp (unix * 1000) into a string date.
	 *
	 * @param {num} date the date to format
	 * @param {string} dateString the formatted date in format: 31 May 2021
	 * @public This is a public method
	*/
	formatDateWithoutDay: date => {
		if (date == "") {
			return "";
		}
		const monthArr = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec"
		];
		var date = new Date(date);
		var dateString =
			date.getDate() +
			" " +
			monthArr[date.getMonth()] +
			" " +
			date.getFullYear();

		return dateString;
	},
	/**
	 * Turns a JS timestamp (unix * 1000) into a string date.
	 *
	 * @param {num} date the date to format
	 * @param {string} dateString the formatted date in format: Mon 31 May
	 * @public This is a public method
	*/
	formatDateWithoutYear: date => {
		if (date == "") {
			return "";
		}
		const monthArr = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec"
		];
		var date = new Date(date);
		var dateString =
			date.getDate() +
			" " +
			monthArr[date.getMonth()];

		return dateString;
	},

	/**
	 * Turns a JS date into ISO formatted date: 2022-02-24
	 *
	 * @param {date} date the date to format
	 * @param {return} return returns the ISO formatted date in format: 2022-02-24
	 * @public This is a public method
	*/
	formatDateISO(date) {
		var formatted = date.getFullYear() + '-' +
			('0'+ (date.getMonth()+1)).slice(-2) + '-' +
			('0'+ date.getDate()).slice(-2);
		return formatted;
	},

	/**
	 * Takes a date and displays the time only in format: 09:50
	 *
	 * @param {date} date the date
	 * @param {return} return returns time in format: 09:50
	 * @public This is a public method
	*/
	formatTime: function (dateTime) {
		let date = new Date(dateTime);
		let hours = date.getHours();
		let mins = date.getMinutes();
		if(mins.toString().length == 1) {
			mins = "0" + mins.toString();	
		}
		if(hours.toString().length == 1) {
			hours = "0" + hours.toString();
		}
		return hours + ":" + mins;
	},

	/**
	 * Takes a number of minutes and converts to a timespan in format: in format: 2h 25m
	 *
	 * @param {num} minutes the number of minutes
	 * @param {return} return returns timespan in format: 2h 25m
	 * @public This is a public method
	*/
	formatTimeRange: function (num) {
		let hours = Math.floor(num / 60);  
		let minutes = num % 60;
		return hours + "h " + minutes + "m";    
	},

	/**
	 * Takes a number of seconds and converts to a time in format: in format: 16:55
	 *
	 * @param {num} seconds the number of seconds
	 * @param {return} return returns timespan in format: 16:55
	 * @public This is a public method
	*/
	formatSecondsAsTime(seconds) {
		let timestamp = seconds * 1000;
		let time = new Date(timestamp).toISOString().substr(11, 5);

		return time;
	},
	/**
	 * Takes a month index and returns the month name (short format)
	 *
	 * @param {num} monthNumber the number of the month
	 * @param {return} return returns the month name in short format
	 * @public This is a public method
	*/
	toMonthName(monthNumber) {
		const date = new Date();
		date.setDate(1); // Set the day to the 1st of the month - to handle bugs when the current date is the last day of the month. Don't remove.
		date.setMonth(monthNumber - 1);
		let monthName = date.toLocaleString('en-US', {
			month: 'short',
		});

		return monthName;
	
	},

	/**
	 * Takes price data and adds the appropriate currency symbol, and formats to the nearest integer.
	 *
	 * @param {string} currency the currency in format: GBP
	 * @param {num} price the price
	 * @param {num} howMany the quantity (default 1)
	 * @param {return} return returns the overall price after formatting
	 * @public This is a public method
	*/
	formatPrice: function (currency, price, howMany = 1, decimals = 2) {
		let overallPrice = (price * howMany).toFixed(decimals); 
		let currencySymbol;
		switch(currency) {
			case "GBP":
				currencySymbol = "£";
				break;
		}
		return currencySymbol + overallPrice; 
	},
	/**
	 * Takes a image name from the assets folder, and adds the full path
	 *
	 * @param {string} img the image name
	 * @param {return} return returns full path
	 * @public This is a public method
	*/
	getImage: (img, includesExtension = false) => {
		if(includesExtension) {
			return require('../assets/' + img);
		} else {
			return require('../assets/' + img + '.png');
		}
    },

	/**
	 * Takes a currency code (e.g. GBP) and returns the symbol
	 *
	 * @param {string} currencyCode the currency Code
	 * @param {return} return returns currency symbol
	 * @public This is a public method
	*/
	currencySymbol: (currencyCode) => {
		if(currencyCode == "GBP") {
			return "£";
		}
		if(currencyCode == "USD") {
			return "$";
		}
		if(currencyCode == "EUR") {
			return "€";
		}
        return currencyCode; // If no symbol found, return the code for display instead
    },
	containsHTML: (str) => {
		var a = document.createElement('div');
		a.innerHTML = str;

		for (var c = a.childNodes, i = c.length; i--; ) {
			if (c[i].nodeType == 1) return true;
		}

		return false;
	},
	containsEmail: function (text) { 
		var re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
		return re.test(text);
	},

	mealIcon(meal) {
		if(meal == "breakfast" || meal == "lunch" || meal == "dinner") {
			return require("@/assets/tours/" + meal + "-icon.png");
		}
		// Default to snacks icon for anything unknown
		return require("@/assets/tours/snacks-icon.png");
	},
	set
}

/* Helper function to set nested object properties (even if a parent property doesn't exist) 
	Example: const input = {};
	set(input, ['a', 'b'], 'hello');
	Result: { a: { b: 'hello' }}
*/
function set(obj, properties = [], value) {
	// this is a super simple parsing, you will want to make this more complex to handle correctly any path
	// it will split by the dots at first and then simply pass along the array (on next iterations)

	// Not yet at the last property so keep digging
	if (properties.length > 1) {
		// The property doesn't exists OR is not an object (and so we overwritte it) so we create it
		if (!obj.hasOwnProperty(properties[0]) || typeof obj[properties[0]] !== "object") obj[properties[0]] = {}
		// We iterate.
		return set(obj[properties[0]], properties.slice(1), value)
		// This is the last property - the one where to set the value
	} else {
		// obj[properties[0]] = value; // Vuex doesn't react to array setting like this. So we use Vue.set
		Vue.set(obj, properties[0], value);
		return true // this is the end
	}
}